<template>
    <page-header-wrapper>
        <a-card>
            <div class="table-page-search-wrapper">
                <a-form layout="inline" class="table-head-ground">
                    <div class="table-layer">
                        <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
                            <a-range-picker @change="onChange" :value="createdDate" :showTime="{
                                hideDisabledOptions: true,
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                                <a-icon slot="suffixIcon" type="sync" />
                            </a-range-picker>
                        </a-form-item>
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" icon="search" @click="queryFunc"
                                :loading="btnLoading">搜索</a-button>
                            <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
                            <span style="margin-left: 20px;">
                                <a-upload v-model:file-list="fileList" name="file" accept=".zip" :action="action"
                                :headers="headers" @change="handleChange" :showUploadList="false">
                                <a-button type="primary">
                                    上传汇付日对账压缩包文件
                                </a-button>
                            </a-upload>
                            </span>
                        </span>

                    </div>
                </a-form>
            </div>
            <!-- 列表渲染 -->
            <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true" :closable="true"
                :searchData="searchData" :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns"
                rowKey="refundOrderId" :scrollX="1300">
                <template slot="handleStateSlot" slot-scope="{record}">
                    {{ handleStateObj[record.handleState] }}
                </template>
            </JeepayTable>
        </a-card>
    </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { req, API_URL_RECONCILIATION_UPLOADFILE } from '@/api/manage'
import moment from 'moment'
import update from './update'
const tableColumns = [
    { key: 'fileName', title: '文件名称', dataIndex: 'fileName', width: '180px', ellipsis: true, },
    { key: 'createAt', title: '创建时间', dataIndex: "createAt", width: '180px' },
    { key: 'handleState', title: '处理状态', scopedSlots: { customRender: 'handleStateSlot' }, width: '180px' },
    { key: 'handleDesc', title: '备注', dataIndex: 'handleDesc', width: '260px', ellipsis: true },
]
export default {
    name: 'ReconciliationAdapayCharge',
    mixins: [update],
    components: {
        JeepayTextUp,
        JeepayTable,
        JeepayTableColumns
    },
    data() {
        return {
            btnLoading: false,
            tableColumns: tableColumns,
            searchData: {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                recordType : "0"
            },
            handleStateObj: {
                "0": "已上传",
                "1": "处理中",
                "2": "处理成功",
                "3": "处理失败",
            },
            createdDate: [],
        }
    },
    mounted() {

    },
    methods: {
        queryFunc() {
            this.btnLoading = true
            this.$refs.infoTable.refTable(true)
        },
        // 请求table接口数据
        reqTableDataFunc: (params) => {
            return req.list(API_URL_RECONCILIATION_UPLOADFILE, params)
        },
        searchFunc() { // 点击【查询】按钮点击事件
            this.$refs.infoTable.refTable(true)
        },
        moment,
        onChange(date, dateString) {
            this.createdDate = dateString;
            this.searchData.createdStart = dateString[0] // 开始时间
            this.searchData.createdEnd = dateString[1] // 结束时间
        },
        disabledDate(current) { // 今日之后日期不可选
            return current && current > moment().endOf('day')
        },
        reset() {
            this.searchData = {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
            }
            this.createdDate = [];
            setTimeout(() => { this.$refs.infoTable.refTable(true) }, 1000)
        }
    }
}
</script>
<style scoped lang="less">
.statistics-view {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    flex-direction: row;
    display: flex;
    margin-top: 20px;
    padding: 30px 0px 30px 0px;

}

.statistics-item-view {
    flex-direction: column;
    display: flex;
    text-align: start;
    align-items: center;
}

.amount-view {
    font-size: 20px;
    color: #5485f8;
    font-weight: bold;
    margin-top: 10px;
}

.amount-black-view {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
}

.amount-h-view {
    font-size: 20px;
    color: #dbaf0e;
    font-weight: bold;
    margin-top: 10px;
}

.text-view {
    font-size: 14px;
    font-weight: normal;
}

.mx-text {
    font-size: 14px;
    font-weight: normal;
    color: #55a2ff;
    padding-left: 10px;
}

::v-deep .ant-upload-list {
    width: 300px !important;
}
</style>